import React from 'react';
import { graphql } from 'gatsby';

import StaticPage from '../views/Layout/StaticPage';
import { useTranslation } from '../views/Translate';

interface Partner {
  name: string;
  image: string;
  link: string;
  text: string;
}

export const query = graphql`
  query ($language: String!) {
    locale(language: { eq: $language }, ns: { eq: "partners" }) {
      data
    }
  }
`;

export default function Partners({ data }) {
  const { t } = useTranslation();
  const jsonData = JSON.parse(data.locale.data);
  const partners = jsonData['items'] as Partner[];

  return (
    <StaticPage className="my-5" pageview="partners" title={t('partners:title')}>
      <div className="container container--light static-partners static-page">
        {partners.map((partner, index) => (
          <div className="partners__item clearfix row my-5" key={`partner-${index}`}>
            <div className="large-5 medium-5 small-12 columns col-sm-5">
              <a href={partner.link} rel="noopener noreferrer" target="_blank">
                <img alt={partner.name} src={partner.image} />
              </a>
            </div>
            <div className="large-7 medium-7 small-12 columns col-sm-7">
              <h3 className="partners__item__header">{partner.name}</h3>
              <p dangerouslySetInnerHTML={{ __html: partner.text }} />
            </div>
          </div>
        ))}
      </div>
    </StaticPage>
  );
}
